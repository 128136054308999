import React, { useCallback, useState } from "react";
import { Card } from "../Card";
import { Container } from "../Container";
import { Input } from "../Input";
import { Text } from "../Text";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { theme, TypeTheme } from "../../theme";
import { IStyleProps, TypeElementProps } from "../types";
import { Button, type IButton } from "../Button";
import { Image } from "../Image";

const FormCard = styled(Card)<{ theme: TypeTheme } & IStyleProps>`
  background-color: ${(props) => props.theme.color.neutral.lavendar};
`;

const FormContainer = styled(Container)<
  { theme: TypeTheme } & TypeElementProps
>`
  display: grid;
  width: 100%;
  gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content max-content max-content;
`;

const LabelText = styled(Text.Small)<{ theme: TypeTheme }>`
  color: ${(props) => props.theme.color.dark.lavendar};
`;

const FormCardHeadlineText = styled(Text.Headline)<{ theme: TypeTheme }>`
  color: ${(props) => props.theme.color.light.white};

  @media (max-width: ${(props) => props.theme.breakpoint.s}) {
    font-size: ${(props) => props.theme.text.size.l};
  }
`;

const SubmitButton = styled(Button)<{ theme: TypeTheme } & IButton>`
  opacity: ${(props) => (props.disabled ? "0" : "1")};
  transition: opacity 1s ease;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
  margin-left: 10px;
`;

type TypeContact = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

interface IContactForm {
  onSubmit: (contact: TypeContact) => void;
  onClose?: () => void;
}

export const ContactForm = ({ onSubmit, onClose }: IContactForm) => {
  const [contact, setContact] = useState<TypeContact | null>(null);

  const handleSubmit = useCallback(() => {
    if (contact) {
      onSubmit?.(contact);
    }
  }, [contact]);

  const createContactAttributeHandler =
    (attr: "firstName" | "lastName" | "email") => (value: string) =>
      setContact((prevContact) => {
        return {
          ...prevContact,
          [attr]: value,
        };
      });

  return (
    <FormCard size="medium">
      <Card.Header>
        <Container display="flex" alignItems="center">
          <Image
            src="/assets/images/icons/magic_cuppy.png"
            width={60}
            height={80}
            mr={10}
          />
          <FormCardHeadlineText>Lets Keep In Touch!</FormCardHeadlineText>
          {onClose && (
            <CloseButton onClick={onClose}>
              <FaTimes color={theme.color.light.lavendar} size={25} />
            </CloseButton>
          )}
        </Container>
      </Card.Header>
      <Card.Body>
        <FormContainer>
          <Input
            label={<LabelText>First Name</LabelText>}
            value=""
            onChange={createContactAttributeHandler("firstName")}
          />
          <Input
            label={<LabelText>Last Name</LabelText>}
            value=""
            onChange={createContactAttributeHandler("lastName")}
          />
          <Input
            label={<LabelText>Email</LabelText>}
            value=""
            onChange={createContactAttributeHandler("email")}
          />
          <Container display="flex" justifyContent="center">
            <SubmitButton
              variant="primary"
              onClick={handleSubmit}
              disabled={
                !contact?.email || !contact?.firstName || !contact?.lastName
              }
            >
              <Text.Small>Submit</Text.Small>
            </SubmitButton>
          </Container>
        </FormContainer>
      </Card.Body>
    </FormCard>
  );
};
