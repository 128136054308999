import { lighten, darken } from "polished";

const NEUTRAL = {
  WHITE: "#ECE6F5",
  RED_CLOVER: "#BB5AA2",
  LAVENDAR: "#A392C0",
  OLIVE: "#4F5524",
  BROWN: "#662F20",
  ORANGE: "#EF8130",
  YELLOW: "#DFBC43",
  BLUE: "#5D86C5",
  GRAY: "#AAA",
};

const LIGHT = {
  WHITE: lighten(0.15)(NEUTRAL.WHITE),
  RED_CLOVER: lighten(0.15)(NEUTRAL.RED_CLOVER),
  LAVENDAR: lighten(0.15)(NEUTRAL.LAVENDAR),
  OLIVE: lighten(0.15)(NEUTRAL.OLIVE),
  BROWN: lighten(0.15)(NEUTRAL.BROWN),
  ORANGE: lighten(0.15)(NEUTRAL.ORANGE),
  YELLOW: lighten(0.33)(NEUTRAL.YELLOW),
  BLUE: lighten(0.15)(NEUTRAL.BLUE),
  GRAY: lighten(0.15)(NEUTRAL.GRAY),
};

const DARK = {
  WHITE: darken(0.15)(NEUTRAL.WHITE),
  RED_CLOVER: darken(0.15)(NEUTRAL.RED_CLOVER),
  LAVENDAR: darken(0.15)(NEUTRAL.LAVENDAR),
  OLIVE: darken(0.15)(NEUTRAL.OLIVE),
  BROWN: darken(0.15)(NEUTRAL.BROWN),
  ORANGE: darken(0.15)(NEUTRAL.ORANGE),
  YELLOW: darken(0.15)(NEUTRAL.YELLOW),
  BLUE: darken(0.15)(NEUTRAL.BLUE),
  GRAY: darken(0.15)(NEUTRAL.GRAY),
};

const COLOR = {
  NEUTRAL,
  DARK,
  LIGHT,
};

const TEXT = {
  SIZE: {
    XSMALL: "0.5em",
    SMALL: "1em",
    MEDIUM: "1.5em",
    LARGE: "2em",
    XLARGE: "4em",
    XXLARGE: "10em",
  },
};

const SPACE = {
  SIZE: {
    XXSMALL: "0.125em",
    XSMALL: "0.25em",
    SMALL: "0.5em",
    MEDIUM: "1em",
    LARGE: "4em",
    XLARGE: "10em",
    XXLARGE: "14em",
    XXXLARGE: "30em",
    XXXXLARGE: "45em",
  },
};

const RADIUS = {
  XXSMALL: "1px",
  XSMALL: "2px",
  SMALL: "3px",
  MEDIUM: "4px",
  LARGE: "8px",
  XLARGE: "12px",
  XXLARGE: "50px",
  CIRCLE: "100%",
};

export type TypeTheme = typeof theme;

export const theme = {
  color: {
    neutral: {
      white: COLOR.NEUTRAL.WHITE,
      red_clover: COLOR.NEUTRAL.RED_CLOVER,
      lavendar: COLOR.NEUTRAL.LAVENDAR,
      olive: COLOR.NEUTRAL.OLIVE,
      brown: COLOR.NEUTRAL.BROWN,
      orange: COLOR.NEUTRAL.ORANGE,
      yellow: COLOR.NEUTRAL.YELLOW,
      blue: COLOR.NEUTRAL.BLUE,
      gray: COLOR.NEUTRAL.GRAY,
    },
    light: {
      white: COLOR.LIGHT.WHITE,
      red_clover: COLOR.LIGHT.RED_CLOVER,
      lavendar: COLOR.LIGHT.LAVENDAR,
      olive: COLOR.LIGHT.OLIVE,
      brown: COLOR.LIGHT.BROWN,
      orange: COLOR.LIGHT.ORANGE,
      yellow: COLOR.LIGHT.YELLOW,
      blue: COLOR.LIGHT.BLUE,
      gray: COLOR.LIGHT.GRAY,
    },
    dark: {
      white: COLOR.DARK.WHITE,
      red_clover: COLOR.DARK.RED_CLOVER,
      lavendar: COLOR.DARK.LAVENDAR,
      olive: COLOR.DARK.OLIVE,
      brown: COLOR.DARK.BROWN,
      orange: COLOR.DARK.ORANGE,
      yellow: COLOR.DARK.YELLOW,
      blue: COLOR.DARK.BLUE,
      gray: COLOR.DARK.GRAY,
    },
  },
  background: {
    color: {
      base: COLOR.LIGHT.YELLOW,
      primary: COLOR.LIGHT.LAVENDAR,
      secondary: COLOR.DARK.LAVENDAR,
      tertiary: COLOR.DARK.LAVENDAR,
      disabled: COLOR.LIGHT.GRAY,
    },
  },
  text: {
    color: {
      base: COLOR.NEUTRAL.OLIVE,
      primary: COLOR.DARK.LAVENDAR,
      secondary: COLOR.NEUTRAL.WHITE,
      tertiary: COLOR.DARK.LAVENDAR,
      disabled: COLOR.DARK.GRAY,
    },
    size: {
      xs: TEXT.SIZE.XSMALL,
      s: TEXT.SIZE.SMALL,
      m: TEXT.SIZE.MEDIUM,
      l: TEXT.SIZE.LARGE,
      xl: TEXT.SIZE.XLARGE,
      xxl: TEXT.SIZE.XXLARGE,
    },
  },
  space: {
    xxs: SPACE.SIZE.XXSMALL,
    xs: SPACE.SIZE.XSMALL,
    s: SPACE.SIZE.SMALL,
    m: SPACE.SIZE.MEDIUM,
    l: SPACE.SIZE.LARGE,
    xl: SPACE.SIZE.XLARGE,
    xxl: SPACE.SIZE.XXLARGE,
    xxxl: SPACE.SIZE.XXXLARGE,
    xxxxl: SPACE.SIZE.XXXXLARGE,
  },
  size: {
    xs: SPACE.SIZE.MEDIUM,
    s: SPACE.SIZE.LARGE,
    m: SPACE.SIZE.XLARGE,
    l: SPACE.SIZE.XXLARGE,
    xl: SPACE.SIZE.XXXLARGE,
    xxl: SPACE.SIZE.XXXXLARGE,
  },
  border: {
    color: {
      base: COLOR.NEUTRAL.WHITE,
      primary: COLOR.LIGHT.LAVENDAR,
      secondary: COLOR.DARK.LAVENDAR,
      tertiary: COLOR.DARK.LAVENDAR,
      disabled: COLOR.NEUTRAL.GRAY,
    },
    radius: {
      xxs: RADIUS.XXSMALL,
      xs: RADIUS.XSMALL,
      s: RADIUS.SMALL,
      m: RADIUS.MEDIUM,
      l: RADIUS.LARGE,
      xl: RADIUS.XLARGE,
      xxl: RADIUS.XXLARGE,
    },
  },
  breakpoint: {
    s: "600px",
    m: "768px",
  },
};
