import React, { useCallback, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { theme, TypeTheme } from "./theme";
import { Header } from "./components/Header";
import { Nav } from "./components/Nav";
import { Home } from "./pages/Home";
import { Events } from "./pages/Events";
import { Contact } from "./pages/Contact";
import { Gallery } from "./pages/Gallery";
import { Image } from "./components/Image";
import { Container } from "./components/Container";
import { Overlay } from "./components/Overlay";
import { ContactForm } from "./components/ContactForm";
import { Footer } from "./components/Footer/Footer";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: var(--font-type);
    background-color: ${theme.background.color.base};
  }
`;

const Badge = styled(Image)<{ theme: TypeTheme }>`
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(10deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  width: 100px;
  height: 100px;
  animation: rotate 2s infinite linear;
`;

const PageWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 70px;
  left: 0;
  overflow-y: scroll;
  height: calc(100vh - 70px);
`;

const FadeTransition = styled(CSSTransition)`
  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 800ms, transform 800ms;
  }

  &.fade-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 800ms, transform 800ms;
  }
`;

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <TransitionGroup>
      <FadeTransition key={location.pathname} timeout={300} classNames="fade">
        <PageWrapper>
          <Routes location={location}>
            <Route path="/" element={<Home />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/events" element={<Events />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <GlobalFooter />
        </PageWrapper>
      </FadeTransition>
    </TransitionGroup>
  );
}

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Nav
      items={[
        {
          id: "home",
          label: "Home",
          onClick: () => navigate("/"),
          selected: location.pathname === "/",
        },
        {
          id: "gallery",
          label: "Gallery",
          onClick: () => navigate("/gallery"),
          selected: location.pathname === "/gallery",
        },
        {
          id: "events",
          label: "Events",
          onClick: () => navigate("/events"),
          selected: location.pathname === "/events",
        },

        {
          id: "contact",
          label: "Contact",
          onClick: () => navigate("/contact"),
          selected: location.pathname === "/contact",
        },
      ]}
    />
  );
};

const GlobalHeader = () => {
  const navigate = useNavigate();

  const handleHomeClick = useCallback(() => {
    navigate("/");
  }, []);
  return (
    <Header
      image="/assets/images/logos/stacked.png"
      Nav={<Navigation />}
      homeClick={handleHomeClick}
    />
  );
};

const GlobalFooter = () => {
  const navigate = useNavigate();

  const handleHomeClick = useCallback(() => {
    navigate("/");
  }, []);
  return (
    <Footer
      image="/assets/images/logos/footer-badge.png"
      homeClick={handleHomeClick}
    />
  );
};

export default function App() {
  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  const handleCloseContactForm = useCallback(() => {
    setShowContactForm(!showContactForm);
  }, [showContactForm]);

  return (
    <ThemeProvider theme={theme}>
      <Container width="100%" height="100%">
        <Router>
          <GlobalStyle />
          <GlobalHeader />
          <AnimatedRoutes />
        </Router>
        <Badge
          src="/assets/images/logos/circle-badge.png"
          onClick={handleCloseContactForm}
        />
        {showContactForm && (
          <Overlay onClose={handleCloseContactForm}>
            <ContactForm
              onSubmit={(data) => console.log(data)}
              onClose={handleCloseContactForm}
            />
          </Overlay>
        )}
      </Container>
    </ThemeProvider>
  );
}
