import { useCallback } from "react";
import { ContactForm } from "../../components/ContactForm";
import { Overlay } from "../../components/Overlay";
import { Page } from "../../components/Page";
import { Text } from "../../components/Text";

export const Contact = () => {
  const handleCloseContactForm = useCallback(() => {}, []);
  return (
    <Page>
      <ContactForm onSubmit={(data) => console.log(data)} />
    </Page>
  );
};
